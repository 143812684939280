import React, {useEffect, useRef, useState} from "react";

export const DeviceViewTop = ({text}) =>{

    return(
        <React.Fragment>
            <h1>{text}</h1>
        </React.Fragment>
    )

}